import { useState, useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"

import { GlobalWorkerOptions, getDocument } from "pdfjs-dist/legacy/build/pdf"
import * as pdfjsLib from "pdfjs-dist/build/pdf"
import jsQR from "jsqr"
import { version } from "pdfjs-dist"

import "react-resizable/css/styles.css"
import "../Book/book.css"
import "./style.css"

import config from "../../Helpers/config.json"
import { updateOrInsertBookStats, updateOrInsertBookTranslationChecked } from "../../Helpers/statsApiService"
import DashboardNavbar from "../DashboardNavbar/DashbaordNavbar"
import CongratulationModal from "./congratulationModal/congratulation"
import PasswordConfirmation from "../PasswordConfirmation/PasswordConfirmation"
import ReplayAndPlayButton from "../../utils/replayAndPlayButton"
import BookPagination from "../../utils/bookPagination"
import TranslationPopup from "../../utils/translationPopup"
import LanguagePopup from "../../utils/languagePopup"
import DictionaryPopup from "../../utils/dictionaryPopup"

import donut_dark from "../../assets/dashboard/donut.png"
import donut_dark_bottom from "../../assets/dashboard/Donut-light-big.png"

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.js`

const BookDetails = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const bookData = location.state?.SelectedBook || null
  const queryParams = new URLSearchParams(location.search)
  const userLanguage = queryParams.get("lang") || "english"

  const [pagesData, setPagesData] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [allLanguagesSupport, setAllLanguagesSupport] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [allStory, setAllStory] = useState([])
  const [showStory, setShowStory] = useState({})
  const [readData, setReadData] = useState("")
  const [endingIndex, setEndingIndex] = useState(0)
  const [highlightedText, setHighlightedText] = useState("")
  const [isToolTipLoading, setIsToolTipLoading] = useState(false)
  const [apiResponse, setAPIResponse] = useState({})
  const [translatedText, setTranslatedText] = useState({})
  const [highlightPosition, setHighlightPosition] = useState({ top: 0, left: 0 })
  const [selectedText, setSelectedText] = useState("")
  const [isDictionaryOpen, setDictionaryOpen] = useState(false)
  const [isLanguageOpen, setLanguageOpen] = useState(false)
  const [translationOpen, setTranslationOpen] = useState(false)
  const [audioUrl, setAudioUrl] = useState("")
  const [audioLoading, setAudioLoading] = useState(false)
  const [timeSpent, setTimeSpent] = useState(0)
  const [isTimeSpent, setIsTimeSpent] = useState(0)
  const [selectedLanguage, setSelectedLanguage] = useState("French")
  const [audioSource, setAudioSource] = useState(undefined)
  const [showCongrateModal, setShowCongrateModal] = useState(false)
  const [valueCheck, setValueCheck] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [backgroundMusicVolume, setBackgroundMusicVolume] = useState(0.2)
  const [buttonState, setButtonState] = useState({ listen: true, pause: false, resume: false })

  const utteranceRef = useRef(null)
  const substringsRef = useRef([])
  const audioPlayerRef = useRef()
  const mainAudioRef = useRef(null)
  const mainAudioResRef = useRef(null)
  const backgroundMusicRef = useRef(null)
  const backgroundMusicEngRef = useRef(null)

  const languageCodeMap = { english: "en", french: "fr", chinese: "zh-CN", germany: "de", hindi: "hi", urdu: "ur" }
  const backgroundMusicUrl = "https://tts.azure.fictiongenie.com/audio/180126-ambient-morning-chill-with-birds-21348_FinalUrdu.mp3";

  const handleVolumeChange = (event) => {
    const volume = parseFloat(event.target.value)
    setBackgroundMusicVolume(volume)
    backgroundMusicRef.current.volume = volume
  }

  const congratulationModalOpen = () => {
    console.log("value check true", valueCheck);
    console.log("CurrentPage", currentPage);
    console.log("CurrentPage + 2 ", currentPage+2);
    // if (valueCheck === true) {
    if (timeSpent >= 10) {  
      updateOrInsertBookStats(localStorage.getItem("id"), localStorage.profileId, bookData.id, currentPage + 2)
      setShowCongrateModal(true)
    } else {
      navigate("/dashboard")
    }
  }

  const nextPage = () => {
    setAudioUrl(null)
    againPlay()
    if (currentPage !== allStory.length - 1) {
      if (timeSpent >= 10) {
        updateOrInsertBookStats(localStorage.getItem("id"), localStorage.profileId, bookData.id, currentPage + 2)
      }
      setCurrentPage((prev) => prev + 1)
      const timer = isTimeSpent + 1
      setIsTimeSpent(timer)
    }
    return
  }

  const prevPage = () => {
    setAudioUrl(null)
    againPlay()
    if (currentPage !== 0) {
      setCurrentPage((prev) => prev - 1)
    }
    return
  }

  const playBook = async () => {
    setAudioLoading(true)
    const text = readData
    const fileName = "" + userLanguage + "_" + bookData?.id + "_" + showStory?.title + "_" + currentPage + "_6";
    const language = "ur-PK"
    const voice = "ur-PK-UzmaNeural"
    try {
      const response = await fetch(
        "https://tts.fictiongenie.com/generate-speech/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ text, fileName, language, voice }),
        }
      )

      if (response.ok) {
        const data = await response.json()
        if (data.path) {
          setAudioLoading(false)
          setAudioUrl(`https://tts.fictiongenie.com/audio/${fileName}.mp3`)
        }
      } else {
        setAudioLoading(false)
      }
    } catch (error) {
      setAudioLoading(false)
    }
  }

  const fetchAndProcessPdf = async (url) => {
    try {
      setLoadingProgress(0)
      const response = await axios.get(url, {
        responseType: 'arraybuffer',
        onDownloadProgress: progressEvent => {
          const { loaded, total } = progressEvent;
          const percentage = Math.round((loaded * 100) / total);
          setLoadingProgress(percentage);
        }
      })

      const loadingPdfDocument = getDocument({ data: response.data })
      const pdfDocumentInstance = await loadingPdfDocument.promise
      const totalNumPages = pdfDocumentInstance.numPages
      const pages = totalNumPages - 1;
      setTotalPages(pages)

      // Load the initial page
      await loadPageData(currentPage, pdfDocumentInstance)
      setLoadingProgress(100)
      setIsLoading(false)
    } catch (error) {
    }
  }

  const onSpeakText = (index, data, inititialCase = false) => {
    // console.log("data", data);

    if (inititialCase) {
      return setHighlightedText(data);
    }
    setHighlightedText((e) => (e ? `${e}.${data}` : `${data}`));
  };

  const generalCall = () => {
    if (buttonState.listen) {
      speakText();
      setButtonState({ ...buttonState, listen: false, pause: true });
    } else if (buttonState.pause) {
      pauseSpeech();
      setButtonState({ ...buttonState, pause: false, resume: true });
    } else if (buttonState.resume) {
      resumeSpeech();
      setButtonState({ ...buttonState, pause: true, resume: false });
    }
  };

  const againPlay = () => {
    window.speechSynthesis.cancel();
    onSpeakText(0, "", true);
    setReadData(readData);
    setButtonState({
      ...buttonState,
      listen: true,
      pause: false,
      resume: false,
    });
  };

  const speakText = (data = "") => {
    if ("speechSynthesis" in window) {
      if (userLanguage === "hindi") {
        substringsRef.current = pagesData[currentPage]?.text?.split(",");
      } else if (userLanguage === "chinese") {
        substringsRef.current = pagesData[currentPage]?.text
          ?.split(/[。！？]/)
          ?.filter((sentence) => sentence.trim() !== "");
      } else if (userLanguage === "urdu") {
        substringsRef.current = pagesData[currentPage]?.text
          ?.split(/۔|\?|!/)
          ?.filter((sentence) => sentence.trim() !== "");
      } else {
        substringsRef.current = pagesData[currentPage]?.text
          ?.split(/[.!?]/)
          ?.filter((sentence) => sentence.trim() !== "");
      }
      playNext(0);
    } else {
      // console.log('Text-to-speech not supported in this browser.');
    }
  };

  const playNext = (index) => {
    backgroundMusicEngRef?.current?.play();
    if (index >= substringsRef.current.length) {
      setHighlightedText("");
      setButtonState({ ...buttonState, pause: false, listen: true });
      return;
    }


    var substring = substringsRef.current[index];
    if (substring.endsWith("\”") || substring.endsWith("\"") || substring.endsWith("\'") ){
      console.log("inside.",substring);
      substring = substring.slice(0, -1);
      
  }
    utteranceRef.current = new SpeechSynthesisUtterance(substring);

    utteranceRef.current.onstart = () => {
      onSpeakText(index + 1, substring);
    };

    utteranceRef.current.onend = () => {
      playNext(index + 1);
    };

    var voices = window.speechSynthesis.getVoices();
    var customVoice;
    if (userLanguage === "french") {
      customVoice = voices.find((voice) => voice.name === "Google français");
    } else if (userLanguage === "hindi") {
      customVoice = voices.find((voice) => voice.name === "Google हिन्दी");
    } else if (userLanguage === "chinese") {
      customVoice = voices.find(
        (voice) => voice.name === "Google 普通话（中国大陆）"
      );
    } else if (userLanguage === "german") {
      customVoice = voices.find((voice) => voice.name === "Google Deutsch");
    } else if (userLanguage === "urdu") {
      customVoice = voices.find((voice) => voice.name === "Google हिन्दी");
    } else {
      customVoice = voices.find(
        (voice) => voice.name === "Google UK English Female"
      );
    }

    utteranceRef.current.voice = customVoice; // set voicen

    utteranceRef.current.rate = 1.0; // set pitch (0-2) range
    window.speechSynthesis.speak(utteranceRef.current);
  };

  const pauseSpeech = () => {
    backgroundMusicEngRef?.current?.pause();
    if (utteranceRef.current) {
      window.speechSynthesis.pause();
    }
  };

  const resumeSpeech = () => {
    backgroundMusicEngRef?.current?.play();
    if (utteranceRef.current) {
      // Ensure the utteranceRef.current is not null or undefined
      if (window.speechSynthesis.speaking && !window.speechSynthesis.paused) {
        window.speechSynthesis.resume();
      } else {
        againPlay();
        playNext(0);
      }
    }
  };

  const handleTextSelection = () => {
    const selection = window.getSelection();
    const text = selection?.toString().trim();

    if (text) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const position = {
        top: rect.top - 150,
        left: rect.left + window.scrollX + rect.width - 70,
      };

      setHighlightPosition(position);
      setSelectedText(text);

      if (isDictionaryOpen) {
        handleCloseDictionary();
      }

      if (isLanguageOpen) {
        handleCloseLanguage();
        handleDoneLanguageClose();
      }
    }
  }

  const countrySelect = (countryName) => {
    if (!selectedText) {
      toast.warn("Please select text", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const languageCode = languageCodeMap[countryName];
    if (!languageCode) {
      return;
    }

    setIsToolTipLoading(true);

    axios
      .post(`${config["baseUrl3"]}/translate`, {
        text: selectedText,
        dest: languageCode,
      })
      .then(function (response) {
        setTranslatedText(response.data.translated_text);
        setIsToolTipLoading(false);
        updateOrInsertBookTranslationChecked(
          localStorage.getItem("id"),
          localStorage.profileId,
          bookData.id,
          1,
          languageCode,
          selectedText,
          response.data.translated_text
        );
      })
      .catch(function (error) {
        console.log(error);
        setIsToolTipLoading(false);
      });
  }

  const handleCloseDictionary = () => {
    setDictionaryOpen(false);
    setHighlightPosition({ top: 0, left: 0 });
  }

  const handleCloseLanguage = () => {
    setLanguageOpen(false);
    setHighlightPosition({ top: 0, left: 0 });
  };

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
  };

  const handleDoneLanguage = (lang) => {
    setTranslationOpen(true);
    countrySelect(lang);
  };

  const handleDoneLanguageClose = () => {
    setTranslationOpen(false);
  };

  const addAlphaChannelToUint8ClampedArray = (unit8Array, imageWidth, imageHeight) => {
    const newImageData = new Uint8ClampedArray(imageWidth * imageHeight * 4);
    for (let j = 0, k = 0, jj = imageWidth * imageHeight * 4; j < jj;) {
      newImageData[j++] = unit8Array[k++];
      newImageData[j++] = unit8Array[k++];
      newImageData[j++] = unit8Array[k++];
      newImageData[j++] = 255;
    }
    return newImageData;
  };

  const getPageImages = async (pageNum, pdfDocumentInstance) => {
    try {
      const pdfPage = await pdfDocumentInstance.getPage(pageNum);
      const operatorList = await pdfPage.getOperatorList();
      const validObjectTypes = [
        pdfjsLib.OPS.paintImageXObject,
        pdfjsLib.OPS.paintImageXObjectRepeat,
        pdfjsLib.OPS.paintJpegXObject,
      ];

      const imagePromises = operatorList.fnArray.map((element, idx) => {
        if (validObjectTypes.includes(element)) {
          return new Promise((resolve) => {
            const imageName = operatorList.argsArray[idx][0];
            pdfPage.objs.get(imageName, async (image) => {
              const imageUnit8Array = image.data;
              const imageWidth = image.width;
              const imageHeight = image.height;
              const imageUint8ArrayWithAlphaChannel =
                addAlphaChannelToUint8ClampedArray(
                  imageUnit8Array,
                  imageWidth,
                  imageHeight
                );
              const imageData = new ImageData(
                imageUint8ArrayWithAlphaChannel,
                imageWidth,
                imageHeight
              );
              const canvas = document.createElement("canvas");
              canvas.width = imageWidth;
              canvas.height = imageHeight;
              const ctx = canvas.getContext("2d");
              ctx.putImageData(imageData, 0, 0);
              const imageSrc = canvas.toDataURL();
              const decodedData = jsQR(
                imageUint8ArrayWithAlphaChannel,
                imageWidth,
                imageHeight
              );
              resolve({
                imageSrc,
                decodedData: decodedData ? decodedData.data : null,
              });
            });
          });
        }
      });

      const images = await Promise.all(imagePromises);
      return {
        images: images.filter(Boolean),
      };
    } catch (error) {
      console.log(error);
      return {
        images: [],
      };
    }
  };

  const loadPageData = async (pageNum, pdfDocumentInstance) => {
    const pageImages = await getPageImages(pageNum, pdfDocumentInstance)
    const pageText = await extractText(pageNum, pdfDocumentInstance)

    setPagesData((prevPagesData) => ({
      ...prevPagesData,
      [pageNum]: {
        ...pageImages,
        ...pageText,
      },
    }));
    setReadData(pageText)
  }

  const translateText = async (text, destLang, srcLang, pageNum) => {
    try {
      setLoadingProgress(0); // Initialize loading progress
      //console.log("PageNum: ",pageNum)
      var book_read_lang = "english"; // Declare with let to allow reassignment

      if (destLang) {
        switch (destLang.toLowerCase()) {
          case "ur":
            book_read_lang = "urdu"; // Map Urdu to 'ur'
            break;
          case "hi":
            book_read_lang = "hindi"; // Map Hindi to 'hi'
            break;
          case "es":
            book_read_lang = "spanish"; // Map Spanish to 'es'
            break;
          case "de":
            book_read_lang = "german"; // Map German to 'de'
            break;
          case "ar":
            book_read_lang = "arabic"; // Map Arabic to 'ar'
            break;
          case "english":
          default:
            book_read_lang = "english"; // Default to English or use as-is
            break;
        }
      }

      // Send the translation request using axios
      const response = await axios.post(
        // "https://azuretranslation.fictiongenie.com/translate"
        `${config["bookTranslationUrl"]}`,
        {
          // text: text,
          // src: srcLang,
          // dest: destLang,
          text: text,
          src: srcLang,
          dest: destLang,
          book_id: bookData.id,
          book_name: bookData.title,
          book_lang: book_read_lang,
          book_page_no: pageNum,
          profile_id: localStorage.profileId
        },
        {
          onDownloadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentage = Math.round((loaded * 100) / total);
            setLoadingProgress(percentage); // Update loading progress
          }
        }
      );

      if (response.status === 200) {
        // If the request was successful
        const result = response.data;
        //console.log("Result:", result.data.translatedContent);
        setLoadingProgress(100); // Mark loading as complete
        return result.data.translatedContent || text; // Return translated text or fallback to original text
      } else {
        // If the API responded with an error
        console.error("Translation API responded with an error:", response.statusText);
        setLoadingProgress(100);
        return text; // Fallback to original text
      }
    } catch (error) {
      console.error("Error in translation API call:", error);
      setLoadingProgress(100);
      return text; // Fallback to original text in case of error
    }
  };

  const extractText = async (pageNum, pdfDocumentInstance) => {
    try {
      const page = await pdfDocumentInstance.getPage(pageNum);
      const textContent = await page.getTextContent();
      const pageText = textContent.items.map((item) => item.str).join(" ");

      // Extract destination language from URL
      const urlParams = new URLSearchParams(window.location.search);
      const langParam = urlParams.get("lang");
      let destLang = "en"; // Declare with let to allow reassignment

      if (langParam) {
        switch (langParam.toLowerCase()) {
          case "urdu":
            destLang = "ur"; // Map Urdu to 'ur'
            break;
          case "hindi":
            destLang = "hi"; // Map Hindi to 'hi'
            break;
          case "spanish":
            destLang = "es"; // Map Spanish to 'es'
            break;
          case "german":
            destLang = "de"; // Map German to 'de'
            break;
          case "arabic":
            destLang = "ar"; // Map Arabic to 'ar'
            break;
          case "english":
          default:
            destLang = "en"; // Default to English or use as-is
            break;
        }
      }

      // Translate the extracted text
      var translatedText = pageText;
      if (langParam != "english") {
        translatedText = await translateText(pageText, destLang, "en", pageNum);
      }
      return { text: translatedText }
    } catch (error) {
      return { text: "" }
    }
  }

  useEffect(() => {
    let startIndex = pagesData?.[currentPage]?.text?.split(".").join("").indexOf(highlightedText);
    let endIndex = startIndex + highlightedText?.length - 1;
    setEndingIndex(endIndex);
  }, [highlightedText, showStory]);

  useEffect(() => {
    if (currentPage === 0 || currentPage === 4) {
      if (timeSpent === 10) {
        console.log("set value check to true timeSpent", timeSpent);
        setValueCheck(true);
        updateOrInsertBookStats(
          localStorage.getItem("id"),
          localStorage.profileId,
          bookData.id,
          currentPage + 2
        );
      }
    }
  }, [timeSpent]);

  useEffect(() => {
    setAudioSource(audioPlayerRef.current);
  }, [audioPlayerRef, audioSource]);

  useEffect(() => {
    const startTime = new Date().getTime();
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const elapsedTime = Math.floor((currentTime - startTime) / 1000);
      setTimeSpent(elapsedTime);
    }, 1000);
    return () => clearInterval(interval);
  }, [isTimeSpent])

  useEffect(() => {
    const loadAdjacentPages = async () => {
      const url = bookData?.pdfs?.[0]?.pdfPath;
      if (url && totalPages > 0) {
        const pdfDocumentInstance = await getDocument(url).promise;

        if (!pagesData[currentPage]) {
          await loadPageData(currentPage, pdfDocumentInstance);
        }

        if (currentPage < totalPages && !pagesData[currentPage + 1]) {
          await loadPageData(currentPage + 1, pdfDocumentInstance);
        }
      }
    };

    loadAdjacentPages();
  }, [currentPage, bookData, totalPages]);

  useEffect(() => {
    if (bookData?.pdfs?.[0]?.pdfPath) {
      fetchAndProcessPdf(bookData?.pdfs?.[0]?.pdfPath)
    }
  }, [])

  return (
    <div className="new-dashboard-wrapper">
      {isLoading && <div className="loader-overlay"><div className="loader" /><span className="loading-text">{`✨ Story loading...  ${loadingProgress}%! ✨`}</span></div>}
      <div className="dashbaord-donut-container"><img src={donut_dark} className="dashbaord-donut" alt="" /></div>
      <DashboardNavbar ageGroup={bookData?.age_group} selectedText={selectedText} bookData={bookData} userLanguage={userLanguage} setShowModal={setShowModal} />
      <img src={donut_dark_bottom} className="dashbaord-donut-bottom" alt="" />
      <div className="content-wrrapers">
        <div className="sub-content-wrrapers" id="sub-content-wraps">
          <div className="book-main-wraps book-container" style={{ background: `url(${pagesData[currentPage]?.images?.[0]?.imageSrc})` }}>
            <div className="left">
              <div className="book-detail-wrap-sub">
                <div className="left-top">
                  <div className="book-header-main">
                    <div className="book-name-writer-responsive">
                      <h1 className="head-story"> {bookData?.title}</h1>
                      <div className="story-writter-container">
                        <p className="story-wriiter">{pagesData[currentPage]?.chapter}</p>
                      </div>
                      <ReplayAndPlayButton userLanguage={userLanguage} audioLoading={audioLoading} playBook={playBook} audioUrl={audioUrl} mainAudioRef={mainAudioRef} againPlay={againPlay} generalCall={generalCall} buttonState={buttonState} mainAudioResRef={mainAudioResRef} backgroundMusicRef={backgroundMusicRef} backgroundMusicVolume={backgroundMusicVolume} backgroundMusicUrl={backgroundMusicUrl} handleVolumeChange={handleVolumeChange} />
                    </div>
                    <div className={`img-container mt-0 img-container-responsive`}>
                      <div className="dashboard-img-book-cover mt-0 book-cover-responsive">
                        <img className="dashboard-img dashboard-img-responsive" src={pagesData[currentPage]?.images?.[0]?.imageSrc} alt="" />
                        <div className="book-spine"></div>
                      </div>
                    </div>
                  </div>
                  <div className="head-story-responsive">
                    <h1 className="head-story"> {pagesData[currentPage]?.title}</h1>
                    <div className="story-writter-container"><p className="story-wriiter">{pagesData[currentPage]?.chapter}</p></div>
                  </div>
                  <div>
                    <div><h1 className="head-story off-on-without-mobile"> {bookData?.title}</h1></div>
                    {bookData?.pdfs?.length === 0 ? (
                      <div className="not-found-story"><p>No content found!</p></div>
                    ) : (
                      <p className={userLanguage === "urdu" ? "text-story text-story-urdu" : "text-story"} onMouseUp={handleTextSelection}>{" "}
                        <span style={{ backgroundColor: "#ffffff30" }}>{highlightedText}{" "}</span>{" "}
                        {!!highlightedText.length ? pagesData[currentPage]?.text?.slice(endingIndex + 2) : pagesData[currentPage]?.text?.slice(endingIndex + 1)}
                        {highlightPosition.top !== 0 && (
                          <>
                            {isDictionaryOpen && <DictionaryPopup highlightPosition={highlightPosition} selectedText={selectedText} userLanguage={userLanguage} apiResponse={apiResponse} handleCloseDictionary={handleCloseDictionary} />}
                            {isLanguageOpen && !translationOpen && <LanguagePopup highlightPosition={highlightPosition} allLanguagesSupport={allLanguagesSupport} selectedLanguage={selectedLanguage} handleSelectLanguage={handleSelectLanguage} handleCloseLanguage={handleCloseLanguage} handleDoneLanguage={handleDoneLanguage} />}
                            {translationOpen && <TranslationPopup highlightPosition={highlightPosition} selectedLanguage={selectedLanguage} selectedText={selectedText} isToolTipLoading={isToolTipLoading} translatedText={translatedText} handleDoneLanguageClose={handleDoneLanguageClose} />}
                          </>
                        )}
                      </p>
                    )}
                  </div>
                  <BookPagination currentPage={currentPage} prevPage={prevPage} totalPages={totalPages} nextPage={nextPage} congratulationModalOpen={congratulationModalOpen} />
                </div>
                <CongratulationModal showCongrateModal={showCongrateModal} setShowCongrateModal={setShowCongrateModal} />
              </div>
            </div>
          </div>
          <div className="background-container"></div>
        </div>
      </div>
      {<PasswordConfirmation showModal={showModal} setShowModal={setShowModal} route={"/account"} />}
    </div >
  )
}

export default BookDetails